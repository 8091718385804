.logo-section .owl-nav {
  display: none;
}

.megamenu-dropdown .dropdown-toggle::after {
  display: none;
}

.inner-menu-dropdown .rounded-circle.btn-circle {
  width: 70px;
  height: 70px;
}

.inner-menu-dropdown .rounded-circle.btn-circle .fa {
  position: relative;
  top: 16px;
  font-size: 1.4rem;
}
.adv_btn {
  display: none;
}
@media handheld, only screen and (min-width: 621px) and (max-width: 767px) {
  #searchBox {
    position: relative;
    top: 27px;
    width: 100%;
    margin: 0 auto;
  }
  .adv_btn {
    display: block;
  }
    #searchBox .form-control {
    border-radius: 0px !important;
  }
  
  ./*cover-gradiant {
    min-height: 450px !important;
    position: relative !important;
  }*/
  .searchbtn {
    display: none;
  }
  #searchBox .btn.btn-primery.btn-blue {
    width: 100% !important;
    margin-top: 10px;
  }

  #searchBox .input-group-append {
    width: 100%;
  }
  .banner {
    background-size: cover !important;
   }
  .mt-15 {
    margin-top: 0 !important;
  }

.fleft {
    float: none !important;
}
.jobTuple ul li {
    padding-bottom: 3px !important;
}
.jobtags {
    margin-top: -7px !important;
  }
}

@media handheld, only screen and (min-width: 240px) and (max-width: 620px) {
  #searchBox {
    top: 52px !important;
    width: 100%;
    margin: 0 auto;
    background: none !important;
    width: 70% !important;
  }
  #searchBox .form-control {
    border-radius: 0px !important;
  }
  .input-group > .form-control,
  .input-group > .form-floating,
  .input-group > .form-select {
    width: 100% !important;
  }
  ./*cover-gradiant {
    min-height: 450px !important;
    position: relative !important;
  }*/
  .adv_btn {
    display: block;
  }
  .searchbtn {
    display: none;
  }
  .category-list li {
    width: 50% !important;
  }

  .input_search input,
  .search_btn {
    border-radius: 0 !important;
  }

  #searchBox .btn.btn-primery.btn-blue {
    width: 100% !important;
    margin-top: 10px;
  }

  #searchBox .input-group-append {
    width: 100%;
  }
  .exp-salary-container{
  display: inline !important;
}
#similar{
  margin-top: 10px !important;
}
}

@media handheld, only screen and (min-width: 240px) and (max-width: 992px) {
  .company-page-body-wrapper {
    margin-top: -11px !important;
  }
  
  .job_searchbox {
    width: 50% !important;
  }

  .category-list li {
    width: 50% !important;
  }
.nxgn-plain-txt {
    font-size: 12px !important;
   }

.fa-at{
  display: unset !important;
}

.banner {
     background-repeat: no-repeat !important;
     background-size: cover !important;
}
}

.invalid {
  border: 1px solid red !important;
  border-radius: 4px;
}

/* Login Page Css Starts */
.signin-content {
  display: flex;
  display: -webkit-flex;
  padding-top: 67px;
}

.material-icons-name {
  font-size: 18px;
}

.signin-form {
  margin-right: 90px;
  margin-left: 80px;
  padding-bottom: 25px;
}

.signin-image {
  margin-left: 110px;
  margin-right: 20px;
  margin-top: 10px;
}

input {
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgrey;
  padding: 7px 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  margin: 10px;
}

.submit {
  background-color: var(--nxgn_blue_color) !important;
  color: whitesmoke !important;
  transition: box-shadow 0.3s ease-in-out;
}

.submit:hover {
  background-color: var(--nxgn_blue_color_hover) !important;
  color: whitesmoke !important;
  transition: ease-in-out(0.075, 0.82, 0.165, 1);
}

.signin-form,
.signin-image {
  width: 50%;
  overflow: hidden;
  padding: 0 5px;
}

.signup-btn {
  background-color: #274160 !important;
  color: whitesmoke !important;
}

.signup-btn:hover {
  background-color: #114380 !important;
  color: whitesmoke !important;
  transition: ease-in-out(0.075, 0.82, 0.165, 1);
}

.or {
  text-align: center;
  font-weight: bold;
}

.facursor {
  cursor: pointer;
}

/* Login Page Css Ends */

.isexp {
  display: flex;
}

.isexp h6 {
  font-size: 1rem;
  line-height: 24px;
}

.isexp label input[type="radio"] {
  margin: 0px 10px 0px 44px;
}

.isexp label {
  display: inline-flex;
  margin-right: 10px;
}

.offcanvas {
  background-color: var(--light_White) !important;
  z-index: 99999 !important;
}

.offcanvas-body .mt-4.pt-5 {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.float-right {
  float: right;
}

/* Password strength Meter */
.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 16px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.password__label {
  margin-top: 10px;
  margin-bottom: 0px;
}

.strength-Weak::-webkit-progress-value {
  background-color: #ff0400;
}

.strength-Fair::-webkit-progress-value {
  background-color: #ffcc00;
}

.strength-Good::-webkit-progress-value {
  background-color: #00b3ff;
}

.strength-Strong::-webkit-progress-value {
  background-color: #03b11a;
}

.strength-Weak span {
  color: #ff0400;
}

.strength-Fair span {
  color: #ffcc00;
}

.strength-Good span {
  color: #00b3ff;
}

.strength-Strong span {
  color: #03b11a;
}

@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar .nav-item .dropdown-menu {
    display: block !important;
    opacity: 0 !important;
    visibility: hidden !important;
    transition: 0.3s !important;
    margin-top: 0 !important;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80% !important;
    transform: rotateX(-75deg) !important;
    transform-origin: 0% 0% !important;
  }

  .navbar .dropdown-menu.fade-up {
    top: 180% !important;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transition: 0.5s !important;
    opacity: 1 !important;
    visibility: visible !important;
    top: 100% !important;
    transform: rotateX(0deg) !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  background-color: unset !important;
}

.navbar-nav .nav-link {
  color: #2f5888 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.navs_top {
  background-color: #fff !important;
  box-shadow: 0px 1px 2px #f0e9e9;
  width: 100% !important;
  position: fixed !important;
  z-index: 9999 !important;
}

.line.fa.fa-lg {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.trashicon {
  box-sizing: border-box;
  font-size: 20px;
  color: #e22e2e;
  position: relative;
  top: -1px;
  left: 10px;
  cursor: pointer;
}

.edit {
  color: var(--nxgn_orange_color);
  cursor: pointer;
  font-size: 20px;
}

.nxgn-plain-txt {
  font-size: 15px;
  font-family: Arial;
  color: #000;
}

.nxgn-plain-txt.ml {
  margin-left: 0.5rem;
}

.nxgn-blue-txt {
  font-size: 15px;
  font-family: Arial;
  color: #022372;
}

.error_message.alert_box p a {
  text-decoration-line: underline;
}

.cam_icon {
  position: relative;
  margin-top: -106px;
  margin-left: 82px;
}

.cam_icon .fa-camera {
  position: relative;
  z-index: 999;
  color: #fff !important;
  font-size: 20px !important;
}

.img_file {
  opacity: 0;
  width: 0px;
  position: absolute;
  cursor: pointer;
}

.show_image {
  margin-left: -32.5% !important;
  margin-top: -15% !important;
}

.myright a i {
  color: seagreen;
}

.redirect_msg_small {
  font-size: medium;
}

.jobs-list ul.list-group {
  overflow: hidden;
}

ul.list-group:hover {
  overflow-y: scroll;
  cursor: all-scroll;
}

ul.list-group::-webkit-scrollbar {
  display: none;
  width: 0.5em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* contact */
.top-back-contact {
  background: var(--bannerContactUs);
}

.top-back-contact .heading {
  color: #fff;
  padding: 150px 0 170px 140px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
}

.box-sec {
  background: #fff;
  padding: 20px 20px;
  border-radius: 10px;
  border-bottom: 6px solid #2f5888;
  box-shadow: 0px 1px 5px #ccc;
  margin-bottom: 15px;
  text-align: center;
}

.three-box {
  margin-top: -100px !important;
  width: 90%;
  margin: 0 auto;
}

.three-box h2 {
  color: #008cf5;
  font-size: 24px;
  font-weight: 600;
}

.three-box p {
  color: #636363;
  font-size: 14px;
}

.three-box ul {
  padding-left: 2px;
}

.three-box ul li {
  list-style: none;
  font-size: 13px;
}

.cent {
  text-align: center;
}

.cent h3 {
  font-size: 18px;
  font-weight: 600;
  color: #444;
}

.cent .btn {
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 600;
  margin-top: 14px;
}

.form-sec {
  margin-bottom: 50px;
}

.form-sec h3 {
  margin-top: 50px;
  text-align: center;
  color: #0096f7;
  margin-bottom: 47px;
}

.form-sec .form-control:focus {
  box-shadow: none;
}

.btn.sub {
  background: #009ef9;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  padding: 8px 23px;
}

.form-sec {
  margin-bottom: 50px !important;
  width: 70%;
  margin: 0 auto;
}

.ttx {
  margin-bottom: 20px;
}

.contact_box {
  border-radius: 6px !important;
}

textarea.contact_box {
  height: 120px !important;
  resize: none;
}

textarea.ta_fixed_height {
  height: 80px !important;
  resize: none;
}
/* End Contact CSS */

/* Captcha CSS */
.captcha {
  padding: 10px;
  width: 150px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  transform: rotate(-5deg) skewX(-20deg);
  margin: 0 auto;
  max-width: 200px;
  text-align: center;
  font-style: italic;
  font-family: Verdana;
}

.col-md-4 i.fa-refresh {
  color: #0737a7;
  cursor: pointer;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #cf0606;
}

/* modal */
.modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-dialog {
  margin: 30px auto !important;
}

/* Widget With Data CSS */
.hiring_flex {
  display: flex;
}

.grid_list {
  text-align: right;
}

/* Coming Soon banner on non-functional options */
.coming_soon {
  background-image: url("/public/img/coming-soon.svg");
  position: absolute;
  top: -2px;
  right: -2px;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.relative_position {
    position: relative;
}

.img_col {
  position: relative;
}

.coming_soon:hover {
  transform: scale(1.3);
}

.interest li {
  display: inline-table;
}

.interest.mt-3 {
  padding: 0;
  text-align: left;
}

.container2 input:checked ~ .checkmark {
  padding: 10px 30px;
}

.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.interest .checkmark {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 40px;
  margin-bottom: 11px;
  display: inline-table;
  padding: 10px 15px;
  box-shadow: 0px 2px 6px #ccc;
  width: auto;
  color: #333;
  margin-right: 15px;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark {
  background-color: #002b4e;
  display: table;
  list-style: none;
  background: #12a466;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 40px;
  margin-bottom: 11px;
  display: inline-table;
  padding: 10px 15px;
  box-shadow: 0px 2px 6px #ccc;
  color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.interest .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
}

.selected-option {
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 25px;
  font-size: 15px;
  color: #333;
  margin-top: 10px;
}

.selected-option-label {
  margin-right: 5px;
}

.selected-option-close {
  margin-left: 5px;
  color: #666;
  font-weight: bold;
  cursor: pointer;
}

.add-btn {
  padding: 6px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 150px;
  overflow-y: auto;
}

.li {
  padding: 8px;
  cursor: pointer;
}

.li:hover,
.selected {
  background-color: #ccc;
}

.skills {
  color: gray;
}

.snapchat_icon {
  font-size: 30px;
}

.social_icon .css-1jouj2f {
  float: left;
  width: 55px;
}

/* job css section start */
.banner_top {
  /*height: 25vw;*/
  position: relative;
  min-height: 320px;
  max-height: 520px;
}

.banner_top img {
  width: 100%;
}

.load_more {
  text-align: center !important;
}

.load_more .nodata {
  background-color: #ccc !important;
}

.load_more button {
  text-align: center !important;
  background-color: #274160 !important;
  color: #fff !important;
  border: 0 !important;
}

./*cover-gradiant {
  position: absolute;
  width: 100%;
  top: unset;
  bottom: 0;
  left: 0;
  background: -o-linear-gradient(
    269.31deg,
    hsla(0, 0%, 100%, 0.0001) 33.7%,
    rgba(6, 12, 47, 0.4) 86.94%,
    rgba(6, 12, 47, 0.4) 97.53%
  );
  background: linear-gradient(
    180.69deg,
    hsla(0, 0%, 100%, 0.0001) 33.7%,
    rgba(6, 12, 47, 0.4) 86.94%,
    rgba(6, 12, 47, 0.4) 97.53%
  );
  mix-blend-mode: normal;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 412px;
  z-index: 4;
}*/

.company-page-body-wrapper {
  border-radius: 28px 28px 2px 2px;
  background: #f8f8f8;
  position: relative;
  z-index: 99;
  margin-top: -80px;

  margin-bottom: 60px;
}

.company-photo .company-photo-wrapper {
  position: relative;
  height: 119px;
  width: 100px;
  overflow: hidden;
  border-radius: 12px 6px;
  border: 1px solid #d3e1ea;
  margin-top: 27px;
}

.company-photo-wrapper .photo {
  height: 104%;
  width: 104%;
  margin: -2px;
  border-radius: 12px 6px;
}

.company-info .heading-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.heading-line .head-cont {
  font-weight: 700;

  font-size: 20px;
  line-height: 38px;
  letter-spacing: -0.333333px;
  color: #1b2437;
  max-width: 71%;
}

.company-info-rnr {
  padding-left: 12px;
  line-height: 18px;
  font-weight: 600;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.company-info-rnr {
  line-height: 18px;
  font-weight: 600;
}

.company-info-rnr .star {
  color: #ffc000;
  padding-left: 4px;
}

.company-info-rnr .reviews {
  line-height: 16px;
  color: #8292b4;
  letter-spacing: 0.433333px;
  padding-left: 8px;
}

.company-info .tag-line {
  font-size: 15px;
  padding-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.company-info-tags {
  padding-top: 14px;
}

.company-info-tags .chips {
  display: inline-block;
  color: #445578;
  padding: 6px 12px;
  border: 1px solid #d3e1ea;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  margin-bottom: 8px;
  margin-right: 6px;
  font-size: 15px;
}

.company-info {
  position: relative;
  left: 0;

  padding-top: 20px;
}

.follow-button-container {
  position: absolute;
  right: 0;
  top: 39px;
}

.follow-cont .followers {
  line-height: 16px;
  color: #8292b4;
  letter-spacing: 0.433333px;
  padding-left: 8px;
}

.follow-cont .follow-button {
  vertical-align: text-top;
  cursor: pointer;
  margin-left: 16px;
  padding: 12px 24px;
  background: #457eff;
  border-radius: 30px;
  font-weight: 600;
  color: #fff;
}

.follow-cont .follow-button {
  cursor: pointer;
  font-weight: 600;
  color: #fff;
}

.jobTuple {
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  padding: 24px;
  border-radius: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eaf1f5;
  -webkit-box-shadow: 0 4px 10px rgba(0, 77, 255, 0.04);
  box-shadow: 0 4px 10px rgba(0, 77, 255, 0.04);
  margin-top: 20px;
  background: #fff;
}
.jobTupleCursor {
    cursor: pointer !important;
}

.dpflex,
.dpflexcol {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  /* display: flex !important; */
  font-size: 14px;
}

.fa-edit:before {
  font-size: 14px !important;
}

.fonticon {
  padding-right: 6px !important;
}

.jobTuple .jobTupleHeader .title {
  color: #1b2437 !important;
  max-width: 100%;
  display: inline-block;
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
}

.companyInfo {
  font-size: 15px;
  font-weight: 600;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.companyInfo .subTitle {
  max-width: 250px;
}

.starRating {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 8px;
  font-size: 13px;
  position: relative;
  top: -1px;
}

.jobTupleHeader .reviewsCount {
  font-size: 13px;
  font-weight: 400;
  color: #8d9cbd;
}

.cmp-exp {
  margin-right: 10px;
}

.jobTuple ul li span {
  /* max-width: 145px; */
  display: inline-block;
}

.jobTuple ul li {
  position: relative;
  list-style: none;
}

.ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}

.jobTuple .job-description {
  margin-top: 8px;
  width: 93%;
}

.jobtags {
  margin-top: 8px;
  line-height: 18px;
  width: 93%;
  min-height: 20px;
  overflow: hidden;
}

.mt-15 {
  margin-top: 33px;
}

.type .posted {
  color: #8292b4;
}

.type span {
  line-height: 14px;
  font-size: 11px;
  height: 14px;
  display: inline-block;
}

.fleft {
  float: left;
}

.mt-15.dpflex {
  padding-left: 0;
  margin-bottom: 0px;
}

.jobTuple .fa {
  color: #8292b4;
}

.mt-20 {
  margin-top: 20px;
}

.jobTuple .jobTupleHeader > .info {
  width: calc(100% - 135px);
  padding-top: 2px;
}

.saveJobContainer {
  cursor: pointer;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.fright {
  float: right;
}

.jobTupleHeader .subTitle,
.jobTuple .jobTupleHeader .title {
  color: #1b2437 !important;
  max-width: 100%;
  display: inline-block;
  line-height: 22px;
  text-overflow: ellipsis;
}

.fa-star {
  color: #ffcc33 !important;
}

/* job css section End */

/* icon css section start  */
.materical_icons {
  font-size: 17px !important;
  color: #8292b4 !important;
  margin-right: 8px !important;
}

/* icon css section  End  */

/* jobDetails Sectin start */
.jobs_details {
  padding-top: 70px;
}

.jd-header {
  background: #fff;
  -webkit-box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  border-radius: 20px;
  padding: 24px;
}

.jd-header-title,
.text-18Bold {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: #2b2a2a;
}

.sim-jobs-right {
  padding: 20px;
  background: #fff;
  border: 1px solid #e3e3ef;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sim-jobs-right h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #555;
}

.jd-header-comp-name a {
  margin-top: 4px;

  margin-right: 12px;
  display: inline-block;
}

.reviewsCount.ml-2.lh16 {
  font-size: 12px;
}

.jd-header a {
  color: #555;
}

.jd-header .comp-banner {
  height: 100%;
  padding: 5px;
}

.jd-header .send-job {
  color: #194ee3;
  margin-top: 18px;
  font-weight: 600;
}

.jd-header .left .exp-salary-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.salary {
  margin-top: 0;
  display: flex;
}

.grey-separator {
  width: 1px;
  height: 14px;
  background-color: #9e9c9c;
  margin-left: 10px;
  margin-right: 10px;
}

.exp-salary-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.salary span {
  line-height: 13px;
}

.exp-salary-container .fa {
  color: #777;
}

.siml .fa {
  color: #777;
}

.siml {
  color: #444;
}

.jd-header .bottom,
.jd-header .jd-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin-top: 7px;
}

.jd-header .bottom {
  border-top: 1px solid #e8e8e8;
  padding-top: 18px;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jd-header .stat > span {
  color: #0f0f1e;
}

.jd-header .stat > span {
  color: #0f0f1e;
  margin-left: 3px;
}

.jd-header .stat {
  padding-right: 6px;
  margin-right: 6px;
  border-right: 1px solid #e6e6f0;
}

.reg-apply-button {
  background-color: #fff;
  color: #2f5888;
  text-transform: none;
  width: auto;
  border-radius: 40px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  margin-right: 12px;
  border: 1px solid #2f5888;
}

.login-apply-button {
  font-family: Satoshi, sans-serif;
  background-color: #2f5888;
  color: #fff;
  text-transform: none;
  width: auto;
  border-radius: 40px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  border: 1px solid #2f5888;
}

.job-desc {
  margin-top: 16px;
  -webkit-box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  border-radius: 20px;
  padding: 30px;
  background: #fff;
}

.job-desc .dang-inner-html {
  margin-top: 10px;
  line-height: 20px;
  color: #4f5574;
}

.job-desc h2 {
  font-size: 20px;
  color: #555;
  font-weight: 600;
}

.job-desc .other-details {
  margin-top: 20px;
}

.job-desc .details > label,
.job-desc .heading,
.job-desc .report-job,
.text-14Bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding-right: 5px
}

.job-desc .chip {
  margin-top: 10px;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 4px 8px;
}

.about-company {
  width: 100%;
  padding: 24px;
  background: #fff;
  -webkit-box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  border-radius: 20px;
  margin-top: 16px;
}

.about-company h2,
.text-16Bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.about-company .comp-info-detail > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #777;
}

.sim-heading-bottom {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #112;
  font-weight: 600;
  font-size: 20px;
}

.sim-jobs-bottom footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

.sim-jobs-bottom .skills-container .tags-and-skills li.skills-dot {
  position: relative;
  padding-left: 20px;
}

.tags-and-skills li {
  margin-right: 18px;
  font-size: 13px;
}

.tags-and-skills {
  color: #6f789a;
  max-height: 15px;
  padding-left: 0;
}

.posted-date {
  color: #8792b9;
  display: inline-block;
  font-size: 12px;
  float: right;
}

.sim-jobs-right article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  position: relative;
}

.sim-jobs-right .view-all,
.sim-jobs-right h3,
.text-14Bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.sim-jobs-right .comp-logo {
  display: inline-block;
  background-color: #fff;
  vertical-align: top;
  border: 1px solid #e5e5f2;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 14px;
}

.sim-jobs-right .comp-logo img {
  width: 49px;
  height: 49px;
}

.sim-jobs-right .posted-date {
  position: absolute;
  right: 0;
  bottom: 20px;
  color: #7d87aa;
}

.sim-jobs-right .ambition-box .review,
.text-12Medium {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #777;
}

.sim-jobs-right .ambition-box .amb-rating {
  color: #747ea2;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
}

.sim-jobs-right .view-all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 79px;
  height: 32px;
  text-align: center;
  border-radius: 60px;
  background-color: #fff;
  border: 1px solid #3162ec;
  margin: 20px auto auto;
}

.sim-jobs-right .view-all a {
  color: #3162ec;
}

.review-summary {
  padding: 20px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 24px;
}

.review-summary header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.review-summary header h2,
.text-16Bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.review-summary header .read-all,
.text-14Bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1c51e6;
}

.review-summary .title .title-text,
.review-summary header .read-all,
.text-14Bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4d;
}

.review-summary .name-date-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2.5px;
  color: #111123;
}

.name {
  font-size: 14px;
}
.review-summary .name-date-box .separator {
  width: 1px;
  height: 10px;
  background: #c9c9c9;
  border-radius: 1px;
  margin-right: 6px;
  margin-left: 5px;
}
.review-summary .like-title {
  margin-top: 14px;
  color: #1b1b35;
}

.review-summary .like-text {
  margin-top: 10px;
  color: #565d7d;
  font-size: 14px;
  line-height: 18px;
}

.review-summary .powered-by {
  color: #878db0;
  margin-top: 20px;
  display: inline-block;
  font-size: 12px;
}
/* jobDetails Sectin End */
/* Show More */
.spinner-border {
  margin-top: -5.3px !important;
}
/* Load_more */
/* Latest Jobs CSS Start */
.hiringTuple {
  margin-top: 0px !important;
}
.hiringTuple .jobTupleHeader .title {
  font-weight: 500 !important;
}

.hiringTuple .jobTupleHeader .subheading .subTitle {
  color: var(--blue_7a1) !important;
}

.hiringTuple .jobtags {
  margin-top: 0px !important;
}
/* Latest Jobs CSS End */
.mobile_menus {
  display: none !important;
  background: unset !important;
  color: #274160 !important;
  border: none !important;
  font-size: 38px !important;
  margin-top: -4px;
}
/* by job popup box */
.tr-list {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.top-banner h1 {
  text-align: center;
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  text-transform: uppercase;
}
.top-banner h2 {
  text-align: center;
  color: #fff;

  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
}
.section-title h1 {
  font-size: 24px;
  color: #505050;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 45px;
}

.category-list {
  border-radius: 4px;
  overflow: hidden;
}
.tr-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.category-list li {
  float: left;
  width: 20%;
  font-size: 16px;
  background-color: #fff;
  border-right: 1px solid #e6e3e6;
  border-bottom: 1px solid #e6e3e6;
}
.category-list li .list_job {
  color: #b5b5b5;
  display: block;
  padding: 15px 15px;
  height: 150px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.category-list .icon {
  margin-bottom: 5px;
  min-height: 40px;
}

.category-list span {
  display: block;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.category-list .category-title {
  color: #505050;
}

.category-list span {
  display: block;
}

.category-list li a {
  color: #b5b5b5;
}

.category-list .icon img {
  display: inline-block;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(0);
}

.category-list li .list_job:hover {
  background-color: #f5f5f5;
}

.category-list li:hover .icon img {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
}

.job_searchbox {
  width: 30%;
  margin: auto;
}
/* by job popup box */
.vertical-navbar {
  position: absolute;
  top: 75px;
  left: 0;
  min-width: 17rem;
  width: 17rem;

  transition: all 0.4s;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.vertical-navbar:hover {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
.vertical-navbar::-webkit-scrollbar {
  display: none;
  width: 0.5em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.page-content {
  width: calc(100% - 17rem);
  margin-left: 17rem;
  transition: all 0.4s;
  padding-top: 75px !important;
  padding: 0 10px 15px;
  position: relative;
}
#sidebar.active {
  margin-left: -17rem;
}
#content.active {
  width: 100%;
  margin: 0;
}
.navbar-brand:hover {
  opacity: unset !important;
}
.banner_employer {
  padding-top: 60px;
  padding-bottom: 160px;
}
.profile_img_employer {
  /*background: linear-gradient(#0c5ebe 50%, transparent 50%) no-repeat;*/
}
.vertical-navbar .leftcard {
  box-shadow: none;
  margin-top: 0px;
}
.page-content .overlap-row {
  margin-top: 0px;
  min-height: 180px;
}
.page-content .employer_body {
  background-color: var(--white);
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px var(--nxgn_orange_color);
  box-shadow: 0px 2px 5px #ccc;
  margin-left: 0px;
  margin-right: 0px;
}

.myright {
  text-align: right;
}
.auto_scroll {
  overflow: auto;
  box-shadow: 0 4px 8px 0 var(--black_10), 0 3px 10px 0 var(--black_10);
}
.auto_scroll .card {
  margin-top: 0 !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}
.eye-icon {
  color: #000;
  font-weight: bold;
  position: absolute !important;
  top: 9px !important;
  right: 10px !important;
  z-index: 9;
}
.register_col {
  position: relative;
}
.register_col .eye-icon {
  color: #000;
  font-weight: bold;
  position: absolute !important;
  top: 9px !important;
  right: 24px !important;
  z-index: 9;
}
.eye-icon:hover {
  color: #000;
  cursor: pointer;
}
.password_input {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.bounce-vertically-90:nth-child(2) {
  background: var(--nxgn_orange_color) !important ;
}
.bounce-vertically-90:nth-child(1) {
  background: #2f5888 !important ;
}
.bounce-vertically-90:nth-child(3) {
  background: #224530 !important ;
}

.profile_image_outline {
  border: 2px solid var(--nxgn_orange_color);
}

.atlwdg-trigger.atlwdg-RIGHT {
  position: fixed !important;
  z-index: 9999 !important;
}

.icon .materical_icons {
  font-size: 40px !important;
}
.logo-slider {
  border: 1px solid #ccc !important;
  background-color: #fff;
  margin-bottom: 30px;
}
.slick-prev:before,
.slick-next:before {
  color: #463f3f !important;
}
.slick-dots li button:before {
  color: var(--nxgn_orange_color) !important;
}
.resume_file {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.spins_login {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 60px !important;
  right: 75px;
  top: 100px !important;
  z-index: 9;
}
.social_icon {
  margin-left: 10px;
}
.files label {
  position: relative !important;
  margin-left: -28px !important;
}
.head .myleft {
  font-size: 18px;
}
.cancel_btn {
  display: flex;
  justify-content: end;
}
.cancel_btn .materical_icons {
  color: #e22e2e !important;
  font-size: 33px !important;
}
.rsfile .materical_icons {
  font-size: 25px !important;
  color: green !important;
}
.downolad_resume a {
  display: flex;
  color: #000;
}
.downolad_resume .materical_icons {
  font-size: 25px !important;
  color: #0d6efd !important;
  position: absolute;
  right: 13px;
}
.margin_bottom {
  margin-bottom: 100px !important;
  padding-bottom: 50px !important;
}

.job_posting_header {
  padding: 0px 0;
  font-size: 20px;
  font-weight: bold;
  border-radius: 3px 3px 0 0;
  margin-bottom: 8px;
}
.support_header {
  border-radius: 0 0 10px 10px !important;
}
.job_posting_radio_button {
  margin-top: 29px;
}
.fa-file-word-o {
  margin-left: 5px !important;
}
.personal_data {
  margin-left: -7px !important;
}
.language_skill {
  margin-left: 5px;
}

/* Video Recorder */
.recorder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timer {
  position: absolute;
  top: 180px;
  right: 145px;
  font-size: 2rem;
  color: white;
}
.blank_player {
  max-width: 100%;
}
.recorder-container .submit {
  position: absolute;
  top: 25%;
}
.btn-player {
  color: #114380 !important;
}
.align_video_player {
  text-align: center;
}
/* Video Recorder End */
.master_menu {
  display: inline-block;
  cursor: pointer;
}
span.menu_icon {
  float: left;
  margin-top: 0;
  color: var(--nxgn_orange_color);
}

.master_menu_text {
  float: left;
  padding: 10px;
  color: var(--blue_372);
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 500;
}

.master_menu_header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  margin-top: -40px;
}
.col.menu_border {
  /* border-bottom: 1px solid var(--nxgn_orange_color); */
  padding-top: 12px;
}
.master_card {
  border: 1px solid  var(--nxgn_orange_color);
  max-height: 510px;
  overflow: hidden;
}
.master_card:hover {
  overflow-y: scroll;
  cursor: pointer;
}
.master_card::-webkit-scrollbar {
  width: 0.5em;
}

.master_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.master_card::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

/*Resume Writing css*/
.resume-box {
  width: 90%;
  margin: 0 auto;
  margin-top: -30px;
}
.top-back-subscription {
  background: var(--bannerSubscription);
  padding: 75px 0;
  padding-top: 130px;
  text-align: center;
  background-position: center;
}
.top-back-subscription .heading {
  text-align: center;
  color: #fff;
  background-color: var(--nxgn_green_color);
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px 20px;
  border-radius: 5px;
}

.top-back-subscription .sub-heading {
  text-align: center;
  color: var(--nxgn_green_color);
  background-color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.container-fluid {
  padding: 0 !important;
}

.mid-weidh {
  width: 90%;
  margin: 0 auto;
}
.mid-weidh ul {
  padding: 0px;
  color: #4f4f4f;
  font-weight: 600;
  padding-left: 15px;
}
.mid-weidh ul li {
  list-style: none;
}
.box-sec ul li {
  list-style: none;
  font-weight: 600;
  color: #666;
}
.box-sec ul {
  padding: 0px;
}

/*Create job alert css*/
.payment_image {
  width: 40px;
}

/*table css*/
/**
 * Pricing Options
 */
.pricing-option {
  margin-bottom: 20px;
  border-width: 1px;
  box-shadow: 0 10px 10px #d9e4ee80;
  text-align: center;
  border-radius: 20px;
}
.pricing-option_popular {
  position: relative;
}
.pricing-option_popular::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  height: 0;
  width: 0;
  border-left: 60px solid #ed3e49;
  border-bottom: 60px solid transparent;
}
.pricing-option_popular::after {
  content: "\f0e7";
  font-family: "FontAwesome";
  font-size: 16px;
  position: absolute;
  display: block;
  top: -5px;
  left: 0;
  color: white;
}
.pricing-option__inner {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.pricing-option__inner:hover {
  border-color: rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}
.pricing-option__header {
  padding: 7px 5px;
}
.pricing-option__header h5,
.pricing-option__header .h5 {
  font-weight: 600;
  font-size: 17px;
}
.pricing-option__price {
  padding: 5px;
  color: var(--nxgn_orange_color);
}
.pricing-option-price__currency {
  vertical-align: 90%;
}
.pricing-option-price__number {
  font-size: 36px;
  font-weight: 600;
}
.pricing-option__body {
  padding: 10px 0 15px;
}
.pricing-option__body ul > li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #999;
}
.pricing-option__body ul > li:last-child {
  border-bottom: 0;
}
/* Pricing Options - Joint */
.pricing-options_joint {
  padding: 10px;
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.05);
}
.pricing-options_joint .row {
  margin-left: 0;
  margin-right: 0;
}
.pricing-options_joint [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.pricing-options_joint .pricing-option {
  margin-bottom: 0;
  border: 5px solid transparent;
}
.pricing-options_joint .pricing-option_popular:before {
  top: 0;
  left: 0;
}
.pricing-options_joint .pricing-option_popular:after {
  top: 10px;
  left: 15px;
}
/* Pricing Options - Table */
.pricing-options_table {
  border-radius: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 10px 10px #d9e4ee80;
}
.pricing-options-table__table {
  margin-bottom: 0;
  text-align: center;
}
.pricing-options-table__table > thead > tr > th {
  text-align: center;
  padding: 0;
}
.pricing-options-table__table .pricing-option__price {
  margin: -8px;
}
.pricing-options-table__table .pricing-option_popular:before {
  border-bottom-width: 40px;
  border-left-width: 40px;
  top: 0;
  left: 0;
}
.pricing-options-table__table .pricing-option_popular:after {
  font-size: 12px;
  top: 5px;
  left: 11px;
}
.pricing-options-table__table .check_icon {
  color: var(--nxgn_blue_color);
}
.pricing-options-table__table .cross_icon {
  color: var(--nxgn_orange_color);
}
.pricing-options-table__title {
  vertical-align: middle !important;
  padding-left: 20px !important;
  text-align: left;
}

.pricing_extra_text_style {
  font-size: 17px;
  font-style: italic;
  color: var(--nxgn_orange_color);
  text-align: right;
  margin-top: -10px;
  padding-right: 10px;
}

.pricing-options-table__title strong {
  font-weight: 600;
  color: #444;
}
.r-align {
  text-align: right;
  font-size: 15px;
  color: #666;
}
.mid-weidh1 ul li {
  list-style: none;
}
.doubleArrow_icon {
  color: #0ec3f3;
}

/* search */
.input_search input {
  border-radius: 30px 0 0 30px !important;
  background-repeat: no-repeat;
}
input.form-control.skill_search {
  border-radius: 0px !important;
}
.search_btn {
  font-size: 17px !important;
  border-radius: 0 30px 30px 0 !important;
  line-height: 22.9px !important;
  height: auto !important;
}

.materical_icons.back_icon {
  position: absolute;
  top: 20px;
  font-size: 30px !important;
}

.job_search1 {
  top: 85px !important;
  z-index: 99;
}

.organization_logo {
  text-align: center;
  height: 50px;
  object-fit: scale-down;
}

.jobs_list_logo {
  width: 150px;
  height: 50px;
  border-radius: 4px;
}

.slick-slide img {
  display: block;
}

.employer_img {
  background: #fff;
  width: 180px;
  height: 50px;
  border: 2px solid var(--nxgn_blue_color);
  margin: 3px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 180px;
}
.readonly{
  background-color: #e9ecef !important;
  opacity: 1;
}
.page-content.payment {
  width: 70%;
  margin: auto;
}
.breakup p {
  color: #555;
}
.breakup span {
  text-align: right;
	float: right;
	font-weight: 600;
	color: #555;
}
.billing span{
  text-align: right;
	float: right;
	color: #555;
}

.employer_list_action_icon {
  display: inline-flex !important;
  padding-bottom: 19px !important;
  padding-top: 19px !important;
  height: 80px;
  width: 74px;
}

/* Left Right Panel CSS for Jobs Listing & details */
.jobTuple.right_panel{
  position: sticky;
  top: 50px;
  cursor:default;
}
.jd_highlight{
  border-color: var(--nxgn_blue_color);
}
.complete-profile{
  font-size: 13px;
  background-color: #fff;
  border-radius: 30px;
  align-items: center;
  box-shadow: 0px 0px 5px 1px var(--nxgn_blue_color);
}
.complete-profile .avatar{
  margin-left: 0px;
}
.flashing-message {
  animation: blink 2s infinite;
  color: var(--nxgn_orange_color);
  font-size: 15px;
}
/* .flashing-message a{
  animation:none;
  color: var(--nxgn_blue_color);
} */

@keyframes blink {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.template .materical_icons{
  color: var(--nxgn_green_color) !important;
  font-size: 20px !important;
  margin-left: 5px;
}
.btn.btn-primery.btn-blue.btn-lg.search_btn:active {
  background-color: #2f9dde;
  border-color: #2793d3;
  color: #fff;
  } 
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: .375rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  }
  .editor-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .editor-class .DraftEditor-editorContainer{
    z-index: 0 !important;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }

  .jobTupleRight ul li{
    list-style: inherit !important;
  }

  .avatarNameInitials{
  font-size: 5.25rem !important;
  }

  .search_iconbtn .fa-search{
    font-size: 30px;
    color: #D56928;
   }

.search_iconbtn{
float: right;
display: none;
}

.adv_search {
margin-top: 12px !important;
}
  .sp{
  width: 25px !important;
  height: 25px !important;
}
.sp .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within){
position: unset !important;
}

.dropselect{
  padding-top: 10px;
}

.navs_top .dropdown-menu{
border-radius: 0.4rem !important;
}
.dropdown-item:hover {
  border-radius: 0.4rem !important;
  transition: all 0.5s ease-in-out !important;
}

.rounded-circle.btn-circle:hover {
  transition: all 0.3s ease-in-out !important;
}
/* responsive */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .pricing-option-price__number {
    font-size: 26px;
  }
  .pricing-option-price__currency {
    vertical-align: 46%;
  }
}

@media (min-width: 240px) and (max-width: 767px) {
  #searchBox {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
  }
  .search_result {
    position: absolute;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: calc(100% - 30px);
    max-width: 100%;
  }
  .navbar-nav.mb-2.mb-lg-0:hover .dropdown-menu.dropdown-menu-end {
    display: block;
}
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .signup-content,
  .signin-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }

  .signin-form {
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }

  .signin-image {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
  }

  .form-title {
    text-align: center;
  }

  .signin-form,
  .signin-image {
    width: 100%;
    overflow: hidden;
  }

  .css-4reedn-MuiSpeedDial-root {
    bottom: 25px !important;
    left: 120px;
  }
}

@media handheld, only screen and (min-width: 621px) and (max-width: 768px) {
  .category-list li {
    float: left;
    width: 50%;
  }
  .master_menu_header {
    margin-top: 20px;
  }
}
@media handheld, only screen and (min-width: 280px) and (max-width: 620px) {
  .category-list li {
    float: left;
    width: 50%;
  }
  .master_menu_header {
    margin-top: 20px;
  }
  .pricing-option-price__number {
    font-size: 26px;
  }
  .pricing-option-price__currency {
    vertical-align: 46%;
  }
}

@media (max-width: 991px) {
    .captcha {
        transform: rotate(349deg) skewX(-20deg);
    }

    .downolad_resume a {
        font-size: 10px;
    }

    .profile-head {
        text-align: center !important;
    }

    .pfsticky {
        position: unset !important;
    }

    .no_broadcastAlerts {
        position: unset !important;
    }

    .downolad_resume .materical_icons {
        font-size: 18px !important;
        color: #0d6efd !important;
        position: absolute;
        right: 5px;
    }

    .refresh_btn {
        text-align: center;
    }

    .form-sec {
        width: 90%;
    }

    .footer_bottom {
        text-align: center !important;
    }

    .rounded-circle.btn-circle-login .fa {
        position: relative !important;
        left: -4px !important;
        right: 0 !important;
        top: 0px !important;
        font-size: 1.5rem;
        color: var(--nxgn_orange_color);
        transition: 0.3s ease-in-out !important;
    }

    .offcanvas-end {
        width: 76% !important;
    }

    .signin-form {
        padding-bottom: 25px;
    }

    .action {
        position: absolute;
        text-align: right;
        right: 29px;
        justify-content: end;
    }

    .action_btn {
        position: absolute;
        text-align: right;
        right: -8px;
    }

    .profile_details {
        text-align: center;
    }

    .modal-dialog {
        margin: 30px;
    }

    .grid_list {
        text-align: right;
    }

    .mobile_menus {
        display: block !important;
    }

    .modal-header .btn-close {
        margin-right: 5px !important;
        margin-top: 5px !important;
    }

    .timer {
        top: 30px;
        right: 45px;
    }

    .master_menu_header {
        margin-top: 20px;
    }

    .profile-img {
        position: static !important;
        width: 195px;
        margin: 0 auto;
    }

    .page-content {
        width: 100%;
        margin-left: 0;
    }
}
  @media handheld, only screen and (min-width: 240px) and (max-width: 620px) {
    .top-back-contact {
      background: none;
    }
    .top-back-contact .heading {
      color: var(--nxgn_blue_color);
      padding: 150px 0 170px 110px;
    }
    
    

    .grid_list {
      text-align: right;
    }

    /*.coming_soon {
      position: absolute;
      top: 0px;
      right: 32px;
      left: 41px;
      background-image: url("/public/img/coming-soon.svg");
      height: 50px;
      width: 50px;
    }*/

    .profile_edit {
      bottom: 25px !important;
      left: 120px;
    }

    #sidebar {
      margin-left: -17rem;
    }
    #sidebar.active {
      margin-left: 0;
    }
    #content {
      width: 100%;
      margin: 0;
    }
    #content.active {
      margin-left: 17rem;
      width: calc(100% - 17rem);
    }

    .emp_profile {
      margin-top: 137px !important;
    }
    .profile_img_employer {
      background: none;
      position: absolute;
      top: 55px;
      left: 23px;
    }
      .search_iconbtn{
    display: block;
    position: fixed;
    top: 10px;
    z-index: 999;
    display: flex;
    right: 73px;
  }
  .searchBoxhide{
    display: none !important;
  }
  .searchBoxshow{
    display: block !important;
  }
  .banner {
   
    padding-top: 0px !important;
    margin-top: 0px !important;
}
.banner_hight{
  height: 280px !important;
}
#advheight{
  height: 450px !important;
}
.job_searchhide{
  display: none !important;
}
.job_searchshow{
  display: block !important;
}
.searchBtnHide{
  display: none !important;
}
.searchBtnShow{
  display: block !important;
}
.adv_search {
margin-top: 0 !important;
}
.advance_btnhide{
  display: none !important;
}
.advance_btnShow{
  display: block !important;
}
.searchbtn {
    display: block !important;
}
.toaster-display{
  z-index: 999999 !important;
}
.modal-header .modal-title{
font-size: 15px !important;
}
  }

@media only screen and (max-width: 768px) and (min-width: 280px) {
  @media handheld, only screen and (min-width: 280px) and (max-width: 480px) {
    .skill {
      width: 650px;
    }
    .overlap-row .profile_img_employer {
      background: none;
      position: absolute;
      top: 100px;
      left: 75px;
    }
    .profile-head {
      font-size: 20px;
      color: var(--blue_372);
    }
    .emp_profile {
      padding-left: 12px !important;
      margin-top: 137px !important;
    }
  }
}

/* responsive */
