body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signin-content .alert-dismissible {
  padding-right: 3rem;
  margin-top: 50px;
}
main {
  min-height: 550px;
}

.message {
  padding-top: 79px;
  text-align: center;
}
/* new css */
.offcanvas {
  transition: transform 0.8s ease-in-out !important;
}
.offcanvas-end {
  width: 28% !important;
}
.offcanvas-header .btn-close {
  font-size: 10px !important;
  background-color: var(--nxgn_orange_color) !important;
  border-radius: 1rem !important;

  outline: none !important;
  box-shadow: none !important;

  opacity: 1 !important;
}
.modal-header .btn-close {
  font-size: 10px !important;
  background-color: var(--nxgn_orange_color) !important;
  border-radius: 1rem !important;
  position: absolute;
  right: 16px;
  top: 16px;
  outline: none !important;
  box-shadow: none !important;

  opacity: 1 !important;
}
input {
  outline: none !important;

  box-shadow: unset !important;
}
select {
  outline: none !important;

  box-shadow: unset !important;
}
textarea {
  outline: none !important;

  box-shadow: unset !important;
}

.radio_style {
  margin-right: 5px !important;
}
.action {
  margin-top: -20px !important;
}
.action .fa-pencil {
  font-size: 20px !important;
}

.fa-building,
.fa-envelope,
.fa-phone,
.fa-birthday-cake,
.fa-venus-mars,
.fa-wheelchair-alt,
.fa-map-marker,
.fa-hourglass-half,
.fa-address-book-o,
.fa-users,
.fa-female,
.fa-male,
.fa-history,
.fa-at {
    color: var(--nxgn_orange_color) !important;
    font-size: 18px;
}

.fa-file-pdf-o
{
  color: red !important;
  font-size: 18px;
}
.fa-file-word-o
{
  color: #1976D2 !important;
  font-size: 18px;
}

main {
  min-height: 565px;
}

.log_out {
  position: absolute !important;
  right: 20px !important;
  font-size: 22px !important;
  cursor: pointer;
  color: #2f5888 !important;
}
.head .myleft {
  font-size: 18px;
}

.search_box {
  width: 28%;
}

.search_result {
  position: absolute;
  left: 33.6%;
  top: 49px;
  z-index: 99;
  width: 24%;
  background-color: white;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 5px !important;
  max-height: 245px;
  overflow-y: auto;
}
.search_inner:hover {
  background-color: #efefef;
}

.search_inner {
  padding: 5px 10px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #a7a9af !important;
  /* border-radius:8px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(157, 154, 154, 0.533);
  /* border-radius: 5px; */
}

.empjob_icon {
  justify-content: center;
}
.empjob_icon img {
  width: 100px !important;
  height: 40px !important;
  object-fit:scale-down;
}

/* dropdown */
.dropdownexp {
  margin: 0 auto;
  z-index: 10000;
  width: 300px;
  position: relative;
  height: 15px;
}
.dropdownexp,
.dropdownexp * {
  z-index: 10;
}
.dropdownexp .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  /* font-weight: 500; */
  height: 36px;
  border-radius: 0;
}
.dropdown-content {
  position: absolute;
  border-radius: 8px !important;
  /* top: 110%; */
  top: 38px;
  background-color: #fff !important;
  max-height: 245px;
  overflow-y: auto;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.dropdown-content .item {
  padding: 8px;
  cursor: pointer;
}
.dropdown-content .item:hover {
  background-color: #efefef;
}
.input_search .location_data {
  border-radius: 30px 0 0 30px !important;
  background-repeat: no-repeat;
}
.input_search .employmentTypes {
  border-radius: 0 30px 30px 0 !important;
  background-repeat: no-repeat;
}
.advancedsearch {
    float: right;
    margin-top: 5px;
    color: var(--skyblue_dde);
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    letter-spacing: 1px;
}

/* search */

.input_search input {
  border-radius: 30px 0 0 30px !important;
  background-repeat: no-repeat;
}
input.form-control.skill_search {
  border-radius: 0px !important;
}
.search_btn {
  font-size: 17px !important;
  border-radius: 0 30px 30px 0 !important;
  line-height: 22.9px !important;
  height: auto !important;
}
.edit_Current{
  position: absolute !important;
    right: 21px !important;
}
.materical_icons.back_icon {
  position: absolute;
  top: 20px;
  font-size: 30px !important;
}

main{
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}
.footer_fix{
  margin-top: auto !important;
}
.main_content_height{
  min-height: 100vh !important;
}