@charset "utf-8";

:root {
    --nxgn_blue_color: #2F5888;
    --nxgn_orange_color: #D56928;
    --nxgn_green_color: #224530;
    --nxgn_lgrey_color: #f1f1f1;
    --nxgn_blue_color_hover: #2F5899;
    --blue_f3e: #232f3e;
    --blue_372: #022372;
    --blue_888: #2f5888;
    --blue_7a1: #0d47a1;
    --blue_160: #274160;
    --blue_09b: #2d609b;
    --blue_47f: #28547f;
    --blue_ab8: #007ab8;
    --blue_68e: #02668e;
    --skyblue_3f3: #0ec3f3;
    --skyblue_6c9: #0ea6c9;
    --skyblue_dde: #2f9dde;
    --white: white;
    --white_8e8: #e8e8e8;
    --light_White: #f5f5f5;
    --white_fbfb: #fbfbfb;
    --white_dfdf: #dfdfdf;
    --yellow: #ff9900;
    --yellow_71f: #ec971f;
    --yellow_512: #d58512;
    --yellow_d4e: #f0ad4e;
    --yellow_236: #eea236;
    --gray: #333;
    --light_gray: #969696;
    --gray_acac: #acacac;
    --gray_c3c: #3c3c3c;
    --gray_057: #495057;
    --light_black: rgba(0, 0, 0, 0.125);
    --lighter_black: rgba(0, 0, 0, 0.15);
    --lightest_black: rgba(0, 0, 0, 0.2);
    --black_4: rgba(0, 0, 0, 0.4);
    --black_05: rgba(0, 0, 0, 0.05);
    --black_175: rgba(0, 0, 0, 0.175);
    --black_10: rgb(0 0 0 / 10%);
    --black: #000;
    --red: red;
    --pink_05f: #e4405f;
    --pink_353: #c43353;
    --banner: url(/public/img/77.svg);
    --bannerContactUs: url(/public/img/88.jpg);
    --bannerSubscription: url(/public/img/22.svg);
    --bannerJobSearch: url(/public/img/44.svg);
}

/* CSS Document */

body {
  font-family: "Roboto", sans-serif;
  background: var(--light_White);
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.nxgn_blue{
  color:var(--nxgn_blue_color) !important;
}
.nxgn_orange{
  color:var(--nxgn_orange_color) !important;
}
.nxgn_green{
  color:var(--nxgn_green_color) !important;
}

.nxgn_blue_bg{
  background-color:var(--nxgn_blue_color) !important;
}
.nxgn_orange_bg{
  background-color:var(--nxgn_orange_color) !important;
}
.nxgn_green_bg{
  background-color:var(--nxgn_green_color) !important;
}

.toaster-display {
    background: var(--nxgn_lgrey_color) !important;
    color: var(--nxgn_blue_color_hover) !important;
    font-weight: 500;
    border: solid;
    border-color: var(--nxgn_green_color) !important;
    /*background: "#333"; 
    color: "#fff";*/
}
/* Footer css */

.footer-nav {
  position: relative;
  padding: 10px 0;
  background: var(--white);
  /* margin-top: 15px; */
}

.cxt-icon-facebook {
  background-color: var(--blue_09b);
  border-bottom: 1px solid var(--blue_47f);
}

.cxt-icon-linkedin {
  background-color: var(--blue_ab8);
  border-bottom: 1px solid var(--blue_68e);
}

.cxt-icon-instagram {
  background-color: var(--pink_05f);
  border-bottom: 1px solid var(--pink_353);
}

.cxt-icon-twitter {
  background-color: var(--skyblue_3f3);
  border-bottom: 1px solid var(--skyblue_6c9);
}

.cxt-icon-common,
.social-media .buttons .button {
  display: inline-block;
  height: 35px;
  width: 35px;
  text-decoration: none;
  font-size: 21px;
  padding: 7px 6px 8px 6px;
  border-radius: 3px;
  color: var(--white);
  margin-right: 8px;
  text-align: center;
}

.footer-nav p {
  color: var(--nxgn_blue_color);
  text-align: left;
  margin-bottom: 0px;
  font-size: 14px;
}

.nxgn-footer {
  background: var(--blue_f3e);
  padding-top: 1px;
}

.nxgn-footer .link-title {
  color: var(--yellow);
  margin: 0;
  font-size: 18px;
  margin-bottom: 10px;
}

.nxgn-footer .section {
  padding: 6px 15px 0 15px;
}

.media-group-border {
  background: var(--lighter_black);
  margin: 19px 0 0 0;
  height: 2px;
}

.foot-menu {
  margin-top: 10px;
}

.foot-menu .links {
  padding: 0;
  list-style: none;
  display: block;
}

.foot-menu .links {
  padding: 0;
  list-style: none;
}

.foot-menu .links {
  list-style: none;
}

.foot-menu .links span {
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}

/*banner css*/

.banner {
  background-image: var(--banner);
  /*height: 300px;*/
  background-position: inherit;
  background-size: 100%;
  padding-top: 60px;
  padding-bottom: 145px;
  margin-top: 40px;
}

.banner_job_search {
    padding-top: 0px;
    padding-bottom: 165px;
    background-image: var(--bannerJobSearch);
}

#searchBox {
  background: var(--lightest_black);
  border-radius: 30px;
  padding: 5px;
  padding-left: 6px;
  padding-bottom: 5px;
}

.nxgn-mid {
  height: 200px;
}

#searchBox label {
  color: var(--white);
}

#searchBox .btn.btn-warning {
  margin-top: 32px;
  color: var(--white);
  background: var(--yellow);
  border: 1px solid var(--yellow);
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.less-ped {
  padding-left: 5px;
  padding-right: 5px;
}

#searchBox .form-control {
  height: 40px !important;
  border-radius: 3px;
}

.main-menu {
  margin-top: 23px;
  text-align: right;
}

.main-menu ul {
  list-style: none;
  padding: 0;
}

.main-menu ul li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}

.main-menu ul li a {
  color: #444;
}

.nxgn-head {
  padding-top: 7px;
  padding-bottom: 5px;
  box-shadow: 0px 0px 4px 3px var(--lighter_black) !important;
  border-bottom: 0px;
  position: sticky;
}

.overlap-row {
  margin-top: -60px;
  background-color: var(--white);
  padding: 14px 0;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 2px var(--nxgn_orange_color);
  margin-left: 0px;
  margin-right: 0px;
}

.btn-overlap-text {
  color: var(--gray);
  margin-top: 9px;
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.rounded-circle.btn-circle {
  background: var(--white);
  width: 65px;
  height: 65px;
  transition: box-shadow 0.3s ease-in-out;
  border: 2px solid var(--nxgn_orange_color);
}

.rounded-circle.btn-circle:hover {
  background: linear-gradient(
    to bottom,
    var(--blue_372) 0%,
    var(--blue_372) 100%
  );
  border: none;
  transition: box-shadow 0.3s ease-in-out;
}

.rounded-circle.btn-circle:hover fa {
  color: var(--white);
}

.rounded-circle.btn-circle .fa {
  position: relative;
  left: 0;
  right: 0;
  top: 13px;
  font-size: 1.5rem;
  color: var(--nxgn_orange_color);
}

.rounded-circle.btn-circle .fa:hover {
  position: relative;
  left: 0;
  right: 0;
  top: 13px;
  font-size: 1.5rem;
  text-shadow: -1px 1px 2px var(--black_4);
  color: var(--white);
}

.rounded-circle.btn-circle:hover .fa {
  color: var(--white);
}

.jobs-list {
  border: 1px solid var(--light_black);
}

.jobs-list h5 {
  border-bottom: 1px solid var(--light_black);
  padding: 10px 18px;
  background-color: var(--white);
  margin-bottom: 0;
  color: #7d7d7d;
  font-size: 15px !important;
}

.jobs-list .list-group-item {
  border: none;
  padding: 0.35rem 1.25rem;
  border-bottom: 1px solid var(--white_dfdf);
}

.jobs-section h5 {
  font-size: 15px;
  text-transform: uppercase;
  color: var(--gray);
}

.logo-img .list-group-item {
  border: none;
  padding: 0.35rem 0.35rem;
  text-align: center;
  border: 1px solid var(--white_dfdf);
  margin: 2px 4px;
  cursor: pointer;
}

.logo-img .list-group {
  margin-bottom: 2px;
  border-radius: 0;
  margin-top: 3px;
}

.top-part {
  background: var(--nxgn_orange_color);
  padding: 4px 0px;
}

.top-part p {
  margin: 0px;
  font-size: 13px;
  color: var(--white);
  text-align: center;
}

.jobs-list .list-group-item a {
  color: var(-light_gray);
}

.jobs-section {
  background-color: var(--white);
  padding: 15px;
  border: 1px solid var(--light_black);
}

.jobs-section .item {
  padding: 10px;
}

.jobs-section .jobs-short-details h6 {
  color: var(--gray_c3c);
  font-size: 15px;
  cursor: pointer;
}

.jobs-section .second-heading {
  color: var(-light_gray);
  font-size: 0.8em;
}

.jobs-section .experience,
.jobs-section .location {
  color: var(--gray_c3c);
  font-size: 0.8em;
}

.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: 21%;
  width: 100%;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  font-size: 30px !important;
}

.owl-nav button.owl-prev {
  float: left;
  margin-left: -13px !important;
}

.owl-nav button.owl-next {
  float: right;
  margin-right: -13px !important;
}

.input-group-text {
  height: 100%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

#searchBox .form-control {
  height: 47px;
  border-radius: 0px;
}

.navbar .dropdown-menu {
  padding: 0 0;
}

.navbar .dropdown-item {
  border-bottom: 1px solid var(-white_8e8);
  padding: 0.55rem 1rem;
}

.bg-light {
  background-color: var(--white) !important;
}

.header {
  border-bottom: 2px solidvar(--nxgn_orange_color);
  background-color: var(--white);
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.btn-plus {
  background-color: var(--blue_f3e);
  color: var(--white);
  padding: 10px 20px;
  border: none;
  border-radius: 0;
}

.btn-blue {
  background-color: var(--skyblue_dde);
  color: var(--white);
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.btn-blue:hover {
  background-color: var(--skyblue_dde);
  color: var(--white);
}

.dropdown-menu-right1 {
  right: auto;
  left: auto;
}

.footer-nav .link {
  list-style: none;
  color: var(--nxgn_blue_color);
  display: inline;
  margin-right: 10px;
}

.footer-nav .link a {
  color: var(--nxgn_blue_color);
  font-size: 14px;
}

.footer-nav .link img {
  cursor:pointer;
}

.footer-nav .copy-links {
  margin-bottom: 0;
  text-align: right;
}

.foot-menu .col-12.col-sm-3.col-lg-3 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.jobs-list h5 {
  color: var(--white);
  font-size: 15px !important;
  background: var(--blue_888);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--blue_888);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--gray);
}

.dropdown-menu {
  border-radius: 0;
}

.navbar .dropdown-item {
  border-bottom: 1px solid var(-white_8e8);
  padding: 0.35rem 1rem;
  font-size: 14px;
}

.jobs-list .list-group-item a {
  color: var(--gray);
  font-size: 14px;
}

.list-group img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

a:hover {
  text-decoration: none;
  opacity: 0.9;
}

.dropdown .hover-nennu {
  width: 350px;
  right: 0 !important;
  padding: 20px;
  text-align: center;
  top: -20px !important;
  z-index: 999;
  border: 1px solid var(--black_05);
  border-radius: 20px;
  box-shadow: 0 3px 12px var(--black_175);
}

.dropdown:hover > .dropdown-menu.dropdown-menu-right1 {
  display: block;
}

.signup-sec {
  background: var(--white_fbfb);
  box-shadow: 0px 1px 4px var(--gray_acac);
  padding-top: 9px;
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.signup-sec span {
  float: left;
  background: var(--white);
  padding: 4px 13px;
  border-radius: 50%;
  font-weight: bold;
  border: 1px solid;
  float: left;
}

.btn-warning:hover {
  color: var(--white);
  background-color: var(--yellow_71f);
  border-color: var(--yellow_512);
}

.btn-warning {
  color: var(--white);
  background-color: var(--yellow_d4e);
  border-color: var(--yellow_236);
  font-size: 13px;
}

.form-control {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray_057);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.css-13cymwt-control {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.signup-sec {
  background: var(--white_fbfb);
  box-shadow: 0px 1px 4px var(--gray_acac);
  padding-top: 22px;
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.row.sign-res h3 {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--blue_888);
}

/*Custom CSS */
.overlap-row2 {
  margin-left: 0px;
  margin-right: 0px;
}

.overlap-row2 .rounded-circle.btn-circle {
  float: left;
}

.overlap-row2 .btn-overlap-text {
  float: left;
  padding: 10px;
  color: var(--blue_372);
  font-size: 14px;
}

.text-lft {
  width: 92%;
  display: inline-block;
  padding: 5px 1px 0px 5px;
  border-bottom: 1px solid var(--nxgn_orange_color);
  border-bottom-left-radius: 30px;
  cursor: pointer;
}

.overlap-row2 .card {
  box-shadow: 0 4px 8px 0 var(--black_10), 0 3px 10px 0 var(--black_10);
  margin-bottom: 20px;
  margin-top: 20px;
}

.overlap-row2 .card-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.head .myleft {
  float: left;
}

.head .myright {
  float: right;
}

/*model css */

.modal {
  top: 30px;
  padding: 40px 40px 40px 40px;
}

.modal-content {
  box-shadow: 0px 0px 10px var(--black);
  border: 2px solid var(--nxgn_orange_color);
  background-color: var(--white);
}

.modal-footer .btn-unique {
  background: transparent;
  color: var(--black);
  border: 2px solid var(--blue_7a1);
  padding: 0.2rem 0.5rem;
}

.modal-footer .btn-cancel {
  background: transparent;
  color: var(--black);
  border: 2px solid var(--blue_7a1);
  padding: 0.2rem 0.5rem;
}

.modal.fade .modal-dialog {
  transition: transform 0.8s ease-out, -webkit-transform 0.8s ease-out,
    -o-transform 0.8s ease-out;
}

.modal-header img {
  width: 100px;
  padding: 6px 0px 0px 14px;
}

.modal-header .modal-title {
  text-align: -webkit-auto;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--blue_372);
  padding: 3px 45px 0px 0px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mb-5,
.my-5 {
  margin-bottom: 1rem !important;
}

.w-50 {
  width: 40% !important;
}

.modal-dialog {
  /* max-width:800px; */
  margin: 30px auto;
}

.modal-header .close {
  color: var(--black);
  font-size: 1.7rem;
  padding: 0px;
  margin: 0px;
  width: 22px;
  border-radius: 50%;
}

.image-cover {
  background: #fff;
  width: 180px !important;
  height: 180px !important;
  border-radius: 50%;
  border: 2px solid var(--nxgn_orange_color);
  margin: 3px;
  object-fit: scale-down;
  object-position: center;
}

.image-widget-show {
  height: 50px;
  border-radius: 0%;
  /*border: 2px solidvar(--nxgn_orange_color);*/
  margin: 3px;
  object-fit: scale-down;
  object-position: center;
}

.modal-title {
  line-height: 1.2;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

/* submit button hover  */

button.btn.btn-unique:hover {
  border: 2px solid var(--nxgn_orange_color);
  transition-delay: 0.3s;
}

button.btn.btn-cancel:hover {
  border: 2px solid var(--red);
  transition-delay: 0.3s;
}

/*profile photo section */
.profile-edit-btn {
  border-radius: 30px;
  border: 2px solid var(--blue_372);
}

input.profile-edit-btn:hover {
  border: 2px solid var(--nxgn_orange_color);
}

.profile-head {
    font-size: 20px;
    color: var(--blue_372);
    width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align:middle;
}

.profile-sub-heading label {
  font-size: 16px;
}

.leftcard {
  box-shadow: 0 4px 8px 0 var(--black_10), 0 3px 10px 0 var(--black_10);
  background-color: var(--white);
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10px 0px 10px 0px;
  position: sticky;
  top: 170px;
  padding-left: 11px;
  border-radius: 5px;
}

.rightcard {
  box-shadow: 0 4px 8px 0 var(--black_10), 0 3px 10px 0 var(--black_10);
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 0px 20px 0px;
  position: sticky;
  top: 250px;
}

.rightcard p {
  padding: 10px;
}

/*left section */
.overlap-row2 .rounded-circle.btn-circle {
  width: 45px;
  height: 45px;
}

.overlap-row2 .rounded-circle.btn-circle .fa {
  top: 5px;
  font-size: 1.2rem;
}

.overlap-row2.rounded-circle.btn-circle .fa:hover {
  top: 5px;
  font-size: 1.2rem;
}

.profile-img {
  position: absolute;
  top: -45px;
  left: -20px;
}

.pfsticky {
  position: sticky;
  top: 78px;
  z-index: 1;
}

.no_broadcastAlerts {
  position: sticky;
  top: 50px;
  z-index: 1;
}

.col_half {
  width: 50%;
  float: left;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-open .modal {
  overflow: hidden;
  overflow-y: auto;
}

/*search box */
#searchBox {
  position: relative;
  top: 25px;
  /* left: 244px; */
  width: 70%;
  margin: 0 auto;
}
.search_margin{
    top:92px;
}

/*navbar  */
.navbar-brand {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.navbar-brand img {
  width: 130px;
}

@media handheld, only screen and (min-width: 280px) and (max-width: 767px) {
  .form-txt {
    font-size: 2rem;
    margin-top: 30px;
  }

  .banner {
    background-image: var(--banner);
    height: auto;
    background-position: inherit;
    background-size: auto;
  }
  #searchBox {
    position: relative;
    top: 80px;
  }

  #searchBox .btn.btn-warning {
    margin-top: 24px;
    color: var(--white);
    background: var(--yellow);
    border: 1px solid var(--yellow);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #searchBox label {
    color: var(--white);
    margin-top: 11px;
    margin-bottom: 1px;
  }

  .foot-menu .col-12.col-sm-3.col-lg-3 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .overlap-row2 .col-sm-2,
  .col-sm-7,
  .col-sm-3 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer-nav .copy-links {
    margin-bottom: 0;
    text-align: center;
    padding: 0;
  }

  .overlap-row {
    margin-top: 20px;
  }

  #searchBox .form-control {
    height: 47px;
    border-radius: 0px;
    width: 100%;
    margin-top: 7px;
  }

  .job_search1 {
    top: 55px !important;
  }
  .input_search {
    display: inline-block;
  }
  .input_search input,
  .search_btn {
    border-radius: 0 !important;
  }

  #searchBox {
    background: transparent;
  }

  .dropdown .hover-nennu {
    width: auto;
    right: 0 !important;
    padding: 20px;
    text-align: center;
    top: -30px !important;
    z-index: 1;
  }

  .input-group{
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .profile-img {
    position: unset;
    top: 0px;
    left: 0px;
    text-align: center;
  }

  .pfsticky {
    position: unset;
    top: 0px;
    z-index: 1;
  }

  .profile-head {
    text-align: center;
  }

  .profile-edit-btn {
    float: right;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1240px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1540px !important;
  }
}

@media (max-width: 1200px) {
  #searchBox {
    float: none;
    width: 100%;
  }
}

/*changes on 5th june*/

#searchBox .form-control {
  /* line-height: 36px; */
  border-radius: 0px;
  color: #747a7e;
}

/*model window Resumenew*/
#dropContainer {
  width: 450px;
  height: 100px;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 50px 100px;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
}

.error_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error_message .msg_image {
  width: 40%;
}

.error_message .msg_image1 {
  width: 50%;
  margin-top: 40px;
}

.alert_box {
  width: 80%;
  margin: auto;
}

.landing_message {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  color: chocolate;
  text-align: center;
}

.landing_message.error {
  color: #c60303;
}

/* Footer fix */
.footer_fix {
  width: 100%;
  position: sticky;
  bottom: 0;
}

.model_form-control {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.files input {
  padding: 30px;
  width: 100% !important;
  margin-left: -30px;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.col-100 {
  width: 83%;
  margin-left: 44px;
}

.resume_style {
  width: 106%;
  margin-left: -17px;
}

.modal-header {
  display: block !important;
  padding: 25px !important;
}

.modal-header .btn-close {
  position: absolute !important;
  right: -4px !important;
  top: -2px !important;
}

.justify-content-center {
  margin-bottom: -20px;
}

.fa-edit:before {
  font-size: 20px;
}

.radio_style {
  margin-right: 10px;
}

.rsfile {
  float: right;
  color: green;
}

.styles {
  font-weight: 800;
}

.headline_style {
  display: flex;
  margin-top: 4px;
}

.element_style {
  display: flex;
  align-items: center;
}

.element_styles {
  margin-left: 5px;
  margin-right: 5px;
}

.display {
  margin-bottom: 5px;
}

.fontsize {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--blue_372);
}

/* Responsive by babul */
@media (max-width: 991px) {
  .files input {
    width: 114% !important;
    margin-bottom: 10px !important;
  }
}
